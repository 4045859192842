<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Sug'urtani {{ $t('ACTION.UPDATE') }}</h3>
          </div>
        </div>
        <div class="card-body">
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="4" class="pl-0">
                <v-autocomplete
                  :items="truckList"
                  v-model="truck"
                  :error-messages="truckErrors"
                  item-text="name"
                  item-value="id"
                  label="Mshina raqami"
                  :loading="isLoading"
                  outlined
                  @input="$v.truck.$touch()"
                  @blur="$v.truck.$touch()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4" class="pl-0">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :error-messages="expiresErrors"
                      v-model="expires"
                      label="Tugash sanasi"
                      prepend-inner-icon="event"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      @input="$v.expires.$touch()"
                      @blur="$v.expires.$touch()"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="expires"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" class="pl-0">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :error-messages="expiresErrors"
                      v-model="expires"
                      label="Tugash sanasi"
                      prepend-inner-icon="event"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      @input="$v.expires.$touch()"
                      @blur="$v.expires.$touch()"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="expires"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" class="pl-0">
                <v-text-field
                  :error-messages="reg_numberErrors"
                  v-model="reg_number"
                  label="Reg raqami"
                  outlined
                  @input="$v.reg_number.$touch()"
                  @blur="$v.reg_number.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4" class="pl-0">
                <v-text-field
                  :error-messages="given_byErrors"
                  v-model="given_by"
                  label="Berilgan tashkilot nomi"
                  outlined
                  @input="$v.given_by.$touch()"
                  @blur="$v.given_by.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <div class="mr-2">
            <button
              type="button"
              class="
                btn btn-light-primary
                font-weight-bold
                text-uppercase
                px-9
                py-4
              "
              @click="save"
            >
              Saqlash
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>

<script>
import Swal from 'sweetalert2'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
// import debounce from "debounce";
import { required } from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      menu: false,
      memu2: false,
      expires: '',
      given_by: '',
      reg_number: '',
      truck: ''
    }
  },
  validations: {
    expires: {
      required
    },
    given_by: {
      required
    },
    reg_number: {
      required
    },
    truck: {
      required
    }
  },
  created() {
    this.$store.dispatch('getInsuranceTruckDetails', this.$route.params.id)
    this.$store.dispatch('getTruckLists')
  },
  watch: {
    truckListsDetails: function (val) {
      this.truck = val.truck_info.id
      this.reg_number = val.reg_number
      this.given_by = val.given_by
      this.expires = val.expires
    }
  },
  computed: {
    truckList() {
      const data = this.$store.state.requests.allTruckList
      data.forEach((x) => {
        x.name = x.reg_number_region + ' ' + x.reg_number
      })
      return data
    },
    truckListsDetails() {
      var data = this.$store.state.requests.insuranceTruckDetails
      return data
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    truckErrors() {
      const errors = []
      if (!this.$v.truck.$dirty) return errors

      !this.$v.truck.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    reg_numberErrors() {
      const errors = []
      if (!this.$v.reg_number.$dirty) return errors

      !this.$v.reg_number.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    given_byErrors() {
      const errors = []
      if (!this.$v.given_by.$dirty) return errors

      !this.$v.given_by.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    expiresErrors() {
      const errors = []
      if (!this.$v.expires.$dirty) return errors

      !this.$v.expires.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Sug'urtani {{ $t('ACTION.UPDATE') }}" }
    ])
  },
  methods: {
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: 'Xato',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      } else {
        this.submitStatus = 'PENDING'
        this.submitStatus = 'OK'
        const data = {
          truck: this.truck,
          given_by: this.given_by,
          reg_number: this.reg_number,
          expires: this.expires
        }
        this.$store.dispatch('updategetInsuarences', {
          data: data,
          id: this.$route.params.id
        })
        this.truck = ''
        this.permitted = ''
        this.reg_number = ''
        this.expires = ''
        this.$v.$reset()
      }
    }
  }
}
</script>
